import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const AdminSidebar = (props) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [nav, setNav] = useState(false);

  const logOut = () => {
    localStorage.removeItem('auth-token');
    window.location = '/';
  };
  const toggleNav = () => {
    if (nav) {
      setNav(false);
    } else {
      setNav(true);
    }
  };
  useEffect(() => {
    const getLinks = () => {
      axios
        .get(`/users/${props.username || props.match.params.username}`)
        .then((res) => {
          setUsername(res.data.username);
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    getLinks();
  }, [props.username]);

  // Return loading UI while waiting for GET request, then load user's page once the request goes through.
  // Return NotFound component if GET request does not find the searched username
  return (
    <section className="admin-sidebar sidebar" data-sidebar="">
      <div
        className={classnames('login nav-item sidebar__user', { active: nav })}
        onClick={() => toggleNav()}
      >
        <i></i>
        <div className="sub-nav ps-container ps-theme-default">
          <div className="divider">{`${username}`}</div>
          <ul>
            <li className="sub-nav-item">
              <a href="/help" target="_blank ">
                Help
              </a>
            </li>
            <li className="sub-nav-item">
              <a onClick={logOut}>{t('logout')}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AdminSidebar;
