import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;
const Cards = ({ title, description, link, icon }) => {
  const { t } = useTranslation();
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card
        className="card"
        style={{ width: 300 }}
        cover={
          <img
            style={{ width: 200, padding: '25px' }}
            className="imgcards"
            alt="example"
            src={icon}
          />
        }
      >
        <Meta
          style={{ borderRadius: '10px', textAlign: 'center' }}
          title={title}
          description={description}
        />
      </Card>
    </Link>
  );
};

export default Cards;
