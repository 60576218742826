import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
const CreateAccount = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('clmltitle')} />
      <div className="ulgetstart">
        <h3>
          <span>{t('clml02')}</span>
        </h3>
        <p>
          <span>
            <span>
              <span>{t('clml03')}</span>
              <br />
              {t('clml04')}
            </span>
          </span>
        </p>
        <p>
          <span>
            <span>
              {t('register')}
              <Link to="/signup">  {t('here')}</Link>
            </span>
          </span>
        </p>
        <ol>
          <li>
            <span>
              <span>{t('clml05')}</span>
            </span>
          </li>
          <li>
            <span>
              <span>{t('clml06')}</span>
            </span>
          </li>
          <li>
            <span>
              <span> {t('clml07')}</span>
            </span>
          </li>
          <li>
            <span>
              <span> {t('clml08')}</span>
            </span>
          </li>
          <li>
            <span>
              <span> {t('clml09')}</span>
            </span>
          </li>
        </ol>
        <p>
          <span>
            <span>
              {t('clml10')} <Link to="/help/ticket"> {t('clml11')}</Link>
            </span>
          </span>
        </p>

        <Link to="/help/start">
          <Button> {t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default CreateAccount;
