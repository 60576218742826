import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import formValidation from '../components/FormValidation';
import inputErrors from '../components/InputErrors';


const SignUp = (props) => {
  const { t } = useTranslation();
  const initialState = {
    username: '',
    password: '',
    email: '',
  };

  const [userExists, setUserExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [res, setRes] = useState('');
  const language = localStorage.getItem('language');

  const axiosFunc = () => {
    setEmailExists(false);
    setUserExists(false);
    axios
      .post(`/users/register`, {...values, language})
      .then((res) => {
        localStorage.setItem('newUser', true);
        setValues(initialState);
        setRes(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        if (error === 'Email already exists') {
          setEmailExists(true);
        }
        if (error === 'Username already exists') {
          setUserExists(true);
        }
      });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    setValues,
  } = formValidation(initialState, inputErrors, axiosFunc);

  return (
    <div className="login">
      <div className="container-fluid row global">
        <div className="background-sign-in row">
          <div className="col-2"></div>
          <div className="col-7 align-self-center" style={{ marginTop: '0px' }}>
            <img
              alt=""
              className="logo"
              src="../../../../assets/logo-light.svg"
            />

            <app-signup-form>
              <div className="row">
                <div className="col">
                  <h5 style={{ marginBottom: ' 3rem', fontWeight: 'normal' }}>
                   {t("register")}
                  </h5>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <input
                    className={
                      errors.username || userExists
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    placeholder={t("placeHolderRegisterUser")}
                    required=""
                    type="text"
                  />
                  {(errors.username || userExists) && (
                    <div className="container">
                      <div className="validation-error">
                        {errors.username}
                        {userExists ? 'Username already exists' : ''}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row" style={{ paddingTop: '5%' }}>
                  <input
                    className={
                      errors.email || emailExists
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required=""
                    type="text"
                  />
                  {(errors.email || emailExists) && (
                    <div className="container">
                      <div className="validation-error">
                        {errors.email}
                        {emailExists ? 'Email already exists' : ''}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row" style={{ paddingTop: '5%' }}>
                  <input
                    className={
                      errors.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    placeholder={t("placeHolderLoginPassword")}
                    type="password"
                  />
                  {errors.password && (
                    <div className="container">
                      <div className="validation-error">{errors.password}</div>
                    </div>
                  )}
                  {res && (
                    <div className="container">
                      <div className="validation-error">{res}</div>
                    </div>
                  )}
                </div>
                <br />
                <Link to="/policy" style={{ color: 'white' }}>
                {t("termsService")}
                </Link>
                <br />
                <button
                  className="btn"
                  style={{ fontWeight: 'bold' }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("createAccount")}
                </button>
              </form>
            </app-signup-form>

            <div className="Remember-me row justify-content-center">
              {res && (
                <div className="col BackToLoginDiv">
                  <button
                    onClick={() => props.history.push('/login')}
                    className="BackToLogin"
                    style={{ minWidth: '150px' }}
                  >
                    <span className="no-account-login">back to login</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col Background-Right">
          <h1 style={{ marginTop: '38%', color: 'black' }}>
            <b>
            {t("loginBanner1")}
              <br />
              {t("loginBanner2")}
            </b>
          </h1>
          <div className="mt-5" style={{ fontSize: '1.4vw' }}>
            <p>
              <b>
                {t("loginBanner3")}
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
