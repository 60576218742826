import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import Footer from '../../components/Footer';
import { Collapse } from 'antd';
import link from '../../assests/images/link.JPG';
import deleteaccount from '../../assests/images/deleteaccount.JPG';

const { Panel } = Collapse;

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title="FAQ" description={t('navfaq')} />

      <div className="ulgetstart">
        <Collapse accordion expandIconPosition="right" ghost={true}>
          <Panel header={t('faq1')} key="7" className="textCollapse">
            <span className="spanTextHelp">{t('faq11')}</span>
          </Panel>
          <Panel header={t('faq2')} key="1" className="textCollapse">
            <span className="spanTextHelp">
              {t('faq21')}
              <a href="/signup"> {t('signup')}</a> {t('faq22')}
              <br />
              {t('faq23')}
            </span>
            <br />
            <span className="spanTextHelp">{t('faq24')}</span>
          </Panel>
          <Panel header={t('faq3')} key="2" className="textCollapse">
            <ul>
              <li>
                <span>
                  <span className="spanTextHelp">
                    {t('faq31')}
                    <span>
                      <span className="spanTextHelp">{t('faq32')}</span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span>
                  <span className="spanTextHelp">{t('faq33')}</span>
                </span>
              </li>
              <li>
                <span>
                  <span className="spanTextHelp">{t('faq34')}</span>
                </span>
              </li>
              <li>
                <span>
                  <span className="spanTextHelp">{t('faq35')}</span>
                </span>
              </li>
              <li>
                <span>
                  <span>
                    <span>
                      <span className="spanTextHelp">{t('faq36')}</span>
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span>
                  <span className="spanTextHelp">
                    {t('contact')}
                    <a href="/help/ticket">{t('faq37')}</a>
                    {t('faq38')}
                  </span>
                </span>
              </li>
            </ul>
          </Panel>
          <Panel header={t('faq4')} key="3" className="textCollapse">
            <span className="spanTextHelp">
              {t('faq41')} <br />
              {t('faq42')}
              <a href="/help/ticket">{t('faq43')}</a>
            </span>
          </Panel>

          <Panel header={t('faq5')} className="textCollapse" key="4">
            <span className="spanTextHelp">{t('faq51')}</span>
          </Panel>
          <Panel header={t('faq6')} className="textCollapse" key="5">
            <span className="spanTextHelp">
              {t('faq61')}
              <br />
              <img className="imgCollapse" src={link}></img>
            </span>
          </Panel>
          <Panel header={t('faq7')} className="textCollapse" key="6">
            <span className="spanTextHelp">
              {t('faq71')}
              <ol>
                <li>
                  <a href="/login">{t('login')}</a>
                </li>
                <li>{t('faq72')}</li>
                <li>{t('faq73')}</li>
                <img style={{ paddingTop: '40px' }} src={deleteaccount}></img>
              </ol>
            </span>
          </Panel>
        </Collapse>
        ,
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
