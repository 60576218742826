import React from 'react';
import { useTranslation } from 'react-i18next';
import FormTicket from './FormTicket';
import Footer from '../../components/Footer';
import Navbar from "./Navbar"

const Ticket = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('contactbanner')} description={t('contactbanner2')} />
      <FormTicket />
      <Footer />
    </div>
  );
};

export default Ticket;
