import React from 'react';
import Footer from '../components/Footer';
import {Link} from "react-router-dom"
import logo from '../assests/images/logo.png';

const Policy = () => {
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <div className="header_area">
        <div className="navbar">
          <div className="navbar-header">
            <div className="container">
              <div className="basic-wrapper">
                {' '}
                <a rel="noopener"
                  className="btn responsive-menu pull-right"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  <i className="icon-menu-1" />
                </a>{' '}
                <Link className="navbar-brand" to="/">
            
                  <img
                    src={logo}
                    alt="Lemmonet Influencers"
                    data-src={logo}
                    data-ret={logo}
                    className="retina"
                    style={{ display: 'inline' }}
                  />
             </Link>
              </div>
              <nav className="collapse navbar-collapse pull-right">
                <ul className="nav navbar-nav">
                  <li>
                    <a rel="noopener" href="influencers/" className="link-header">
                      Influencers
                    </a>
                  </li>
                  <li>
                    <a rel="noopener" href="connect/" className="link-header">
                      Connect
                    </a>
                  </li>
                  <li>
                    <a rel="noopener" href="motion/" className="link-header">
                      Motion
                    </a>
                  </li>
                 
                </ul>
              </nav>
            </div>
          </div>
          {/*/.nav-collapse */}
        </div>
        {/*/.navbar */}
        <div className="element-banner2">
          <div className="row">
            <div className="col-sm-2 col-xs-12" />
            <div className="col-sm-8 col-xs-12 text-center">
              <h1
                className="text-title animated fadeInLeft delay-01"
                data-animation="fadeInLeft"
                data-animation-delay="01"
              >
                Privacy Policy
              </h1>
              <div
                className="text-resume animated fadeIn delay-02"
                data-animation="fadeIn"
                data-animation-delay="02"
              >
                <div className="text-line">
                  We are 100% committed to keep your personal data safe
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-xs-12" />
          </div>
        </div>
      </div>
      <div className="area-full">
        <div className="light-wrapper">
          <div className="container inner">
            <div className="row">
              <div className="col-xs-12">
                <p>
                  Tiburtina SA (“Lemmonet”, “we”, “us”, “our”) provides digital
                  advertising services. This Privacy Statement is provided to
                  help you understand how Lemmonet processes personal data on
                  behalf of customers who use our services. It does not state
                  the use practices or policies of our clients, of apps on which
                  ads are shown, or of other parties.
                </p>
                <p>
                  Lemmonet and our customers care about how personal data is
                  collected, used, and shared. We are a “data sub-processor”
                  that collects and analyzes personal data at the specific
                  request of our customers (data controllers), through third
                  party Mobile Measuring Platforms that act as “data
                  processors”.
                </p>
                <p>
                  Lemmonet also collaborates with Programmatic Ad Exchanges that
                  act as “data controllers”. In this scenario, we are “data
                  processors” (and “data co-controllers” in some specific
                  cases). This means we collect and securely forward data to be
                  used for attribution to third party Mobile Measuring
                  Platforms.
                </p>
                <h4>Our Business</h4>
                <p>
                  Lemmonet’s technology powers advertisements that can appear on
                  web sites and applications on mobile phones or other devices
                  connected to the mobile internet. You may interact directly
                  with our servers (for example, when you click on an ad) or
                  indirectly via the mobile web site or application that
                  displays the ads.
                </p>
                <h4>Personal Data</h4>
                <p>
                  Personal data refers to any information about personal or
                  factual circumstances of an identified or identifiable person.
                  This includes information and details such as your name, home
                  address or other postal address, telephone number and also
                  your e-mail address. In the context of Lemmonet, personal data
                  mostly refers to a smartphone device ID such as Google
                  Advertising ID for Android phones or IDFA for iPhones, and IP
                  address.
                </p>
                <h4>Legal basis</h4>
                <p>
                  Your data is handled according to the following legal
                  provisions:
                </p>
                <ul>
                  <li>
                    with regard to data communicated in forms, etc. with your
                    consent, Art. 6(1) (a) GDPR;
                  </li>
                  <li>
                    with regard to services you use for the performance of a
                    contract , Art. 6(1)(b) GDPR;
                  </li>
                  <li>
                    otherwise, with particular regard to statistical data and
                    online identifiers based on legitimate interests, Art
                    6(1)(f) GDPR (see below).
                  </li>
                </ul>
                <h4>Legitimate interests</h4>
                <p>
                  When handling your data, we pursue the following legitimate
                  interests:
                </p>
                <ul>
                  <li>improving our service;</li>
                  <li>protecting against misuse;</li>
                  <li>statistics;</li>
                  <li>protection against ad fraud.</li>
                </ul>
                <h4>Data sources</h4>
                <p>
                  Unless otherwise specified, we receive the data through third
                  parties, that is collected through devices used by app users.
                </p>
                <h4>Data transfer to third countries</h4>
                <p>
                  In order to process and store the data we collect, this Data
                  is sometimes transferred to countries outside the European
                  Union. This is done on the basis of contractual regulations
                  stipulated by law that ensure appropriate protection of your
                  data and that are available for you to view upon request.
                </p>
                <h4>Retention period</h4>
                <p>We retain app user data,</p>
                <ul>
                  <li>
                    if app users have consented to this, until such time they
                    withdraw this consent,
                  </li>
                  <li>
                    if we need the data to undertake an agreement, until such
                    time as the contractual relationship with the app user
                    ceases or legal retention periods expire,
                  </li>
                  <li>
                    if we use the data on the basis of a legitimate interest,
                    until such time as the app user’s overriding interest
                    obliges us to delete or anonymise them.
                  </li>
                </ul>
                <h4>Purposes of use</h4>
                <p>
                  Personal data will only be collected and processed by Lemmonet
                  to the extent and for the purpose 1) for which it was provided
                  by the Data Controller or, 2) in the cases where we are acting
                  as “Data Co-controller”, declared in written contracts with
                  our partners, with explicit consent from the Data Subject.
                </p>
                <p>
                  We use and store your personal data as part of our services
                  for the following purposes, only if you have provided us with
                  your express consent:
                </p>
                <ul>
                  <li>
                    We use this information in order to help deliver more
                    interesting and relevant ads to app users, and provide our
                    customers and partners with reports containing aggregated
                    data (for instance: the number of clicks on those ads, and
                    so on). These reports are used to analyze trends, and to
                    perform fraud detection.
                  </li>
                  <li>
                    We use your personal information only in aggregate and only
                    within the company’s systems and forward it only to those
                    companies that are involved in performing according to the
                    contracts concluded with you or otherwise involved in
                    providing the relevant service. Otherwise, your personal
                    information will not be forwarded to third parties, unless
                    you have given your express consent for this or unless we
                    are obliged to disclose it, for example, at the direction of
                    a court or administrative authority.
                  </li>
                  <li>
                    We only forward the following personal data to third
                    parties: Device identifier, IP address, User Agent,
                    Location.
                  </li>
                </ul>
                <h4>Data protection</h4>
                <p>
                  We have taken extensive technical and organisational measures
                  to secure your data against potential risks, such as
                  unauthorised login or access, unauthorised perusal, amendment
                  or distribution, as well as against loss, deletion or misuse.
                </p>
                <p>
                  In order to protect your personal data against unauthorised
                  access by third parties when being transmitted, we secure data
                  transmissions, if necessary, using SSL encryption.
                </p>
                <p>
                  This is a standard encryption procedure for online services,
                  particularly for the Internet.
                </p>
                <h4>Cookies</h4>
                <p>
                  Cookies are small files that your browser stores on your PC in
                  a directory designated specifically for this purpose. These
                  cookies can be used e.g. to find out whether you have already
                  visited a website. Most browsers accept cookies automatically.
                </p>
                <p>
                  Nevertheless, you can change the settings in your browser, so
                  that no cookies can be stored or so that your explicit
                  agreement is required before any cookies are stored. You can
                  also delete previously set cookies at any time. Please note
                  that disabling cookies may result in your use of our website
                  being restricted.
                </p>
                <p>We use cookies only for the following purposes:</p>
                <ul>
                  <li>
                    Track conversions and advertising campaigns performance. The
                    cookies are set upon user click on the banners and not
                    before.
                  </li>
                  <li>
                    Authentication and user tracking in "connect.lemmonet.com"
                    and “contentcreators.lemmonet.com”.
                  </li>
                </ul>
                <h4>Opt-out</h4>
                <p>
                  If you wish to block these cookies, you will need to change
                  the relevant settings on your mobile device. Some, but not
                  all, mobile platforms allow you to manage settings
                  specifically for the Lemmonet.com domain; on others you can
                  only control cookies at a global level. Details on how to do
                  this vary based on your mobile device operating system and
                  browser, but common cases can be addressed as follows:
                </p>
                <p>iOS</p>
                <ul>
                  <li>
                    From the home screen of your device, select the “Settings”
                    icon
                  </li>
                  <li>Select ‘Safari’ from the list</li>
                  <li>To adjust the cookie settings, tap ‘Accept Cookies’</li>
                  <li>Choose either “Never”, “From visited”, or “Always”</li>
                </ul>
                <p>Android</p>
                <ul>
                  <li>Open the browser app</li>
                  <li>Push the ‘Menu’ button</li>
                  <li>
                    From the options that are shown to be running, select the
                    ‘More’ option
                  </li>
                  <li>
                    Select the ‘Settings’ function from the list of browser
                    functions
                  </li>
                  <li>
                    Scroll down to the ‘Accept cookies’ option. If the box is
                    checked, uncheck it to disable cookies; if the box is
                    unchecked, select it in order to enable cookies.
                  </li>
                </ul>
                <h4>Web analysis</h4>
                <p>
                  Like almost all website operators, we use analytical tools in
                  the form of tracking software to ascertain the number of users
                  using our website and how frequently they visit.
                </p>
                <p>
                  To enable us to optimise our website and our service, we use
                  Google Analytics, which is a web analysis service provided by
                  Google Inc. (“Google”). Google Analytics uses what are known
                  as “cookies”: these are text files stored on your computer and
                  used to analyse your website usage. The information generated
                  by the cookie about your use of this website (including your
                  IP address) will be sent to a Google server in the USA and
                  stored there. However, if IP anonymisation is enabled on this
                  website, within member states of the European Union, or in
                  other states that are party to the agreement on the European
                  Economic Area Google first abbreviates your IP address.
                </p>
                <p>
                  The full IP address will only be transmitted in exceptional
                  circumstances to one of Google's servers in the USA, where it
                  will then be abbreviated. Google will use this information on
                  behalf of our website operator to analyse your use of the
                  website in order to compile reports about website activities
                  for the website operator and to perform other services
                  connected with the use of the website and of the Internet. The
                  IP address transmitted by your browser as part of Google
                  Analytics will not be merged or combined with other data by
                  Google. You can prevent cookies from being stored by applying
                  the appropriate settings in your browser software; please
                  note, however, that in this case you may not be able to use
                  all of the functions of this website to their full extent.
                </p>
                <p>
                  You may also prevent Google from collecting data generated by
                  the cookie referring to your website usage (including your IP
                  address), and from processing these data by downloading and
                  installing the browser plug-in available from the following
                  link{' '}
                  <a rel="noopener"
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                  >
                    https://tools.google.com/dlpage/gaoptout?hl=en
                  </a>
                  .
                </p>
                <p>
                  As an alternative to the browser add-on or within browsers on
                  mobile devices, please click on this link to prevent Google
                  Analytics from collecting data in this website in future (the
                  opt-out only works in this browser, and only for this domain).
                  This stores an opt-out cookie on your device. If you delete
                  your cookies in this browser, you need to click on this link
                  again. Further information is available at{' '}
                  <a rel="noopener"
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                  >
                    https://tools.google.com/dlpage/gaoptout?hl=en
                  </a>{' '}
                  or at{' '}
                  <a rel="noopener"
                    href="https://www.google.com/intl/en/analytics/privacyoverview.html"
                    target="_blank"
                  >
                    www.google.com/intl/en/analytics/privacyoverview.html
                  </a>{' '}
                  (general information on Google Analytics and data protection).
                </p>
                <h4>Third-party services</h4>
                <p>
                  We use third-party services for various functions on our
                  website.
                </p>
                <h4>Rights of the data subject</h4>
                <p>
                  Right to access, rectification, right to object, to complaint,
                  erasure and blockage.
                </p>
                <ul>
                  <li>
                    You have the right to request information about whether and
                    which personal data is processed by our company. You also
                    have the right to demand that your personal data is
                    rectified or amended.
                  </li>
                  <li>
                    Under certain circumstances, you have the right to request
                    that your personal data should be deleted.
                  </li>
                  <li>
                    Under certain circumstances, you have the right to demand
                    that the processing of your personal data should be
                    restricted.
                  </li>
                  <li>
                    You can withdraw your consent to the processing and use of
                    your data completely or partially at any time with future
                    application.
                  </li>
                  <li>
                    You have the right to obtain your personal data in a common,
                    structured and mechanically readable format.
                  </li>
                  <li>
                    If you have any questions, comments, complaints or requests
                    in connection with our statement on data protection and the
                    processing of your personal data, you can also contact our
                    data protection officer in detail.
                  </li>
                  <li>
                    You also have the right to complain to the responsible
                    supervisory authority if you believe that the processing of
                    your personal data is in violation of the legislation.
                  </li>
                </ul>
                <h4>Requirement or obligation to provide data</h4>
                <p>
                  Unless expressly stated, when data is collected, the provision
                  of such data is neither required nor obligatory.
                </p>
                <h4>Changes to Privacy Policy</h4>
                <p>
                  Lemmonet is committed to continually examining and reviewing
                  its privacy practices, and may make changes to its Privacy
                  Policy. In the event that material changes are made, the
                  Privacy Policy on this website will be changed.
                </p>
                <h4>Questions and Concerns</h4>
                <p>
                  If you have any questions or concerns about our privacy
                  practices, you may contact us:
                </p>
                <ul>
                  <li>
                    <strong>Tiburtina SA</strong>
                  </li>
                  <li>Cesar Cortinas 2037, Montevideo, Uruguay CP 11500</li>
                  <li>
                    Email:{' '}
                    <a rel="noopener" href="mailto:privacypolicy@lemmonet.com">
                      privacypolicy@lemmonet.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /.light-wrapper */}

        <Footer />
      </div>
    </div>
  );
};
export default Policy;
