import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Theme from './Theme';

const Appearance = ({ username }) => {
  const { t } = useTranslation();
  const [newTheme, setNewTheme] = useState(0);



  return (
    <>
      <section className=" section settings-container userpreview">
        <div className=" section color-container">
          <h3>{t("themes")}</h3>
          <div className="section__inner themes-container ">
            <Theme activeTheme={newTheme} username={username} theme={1} />
            <Theme activeTheme={newTheme} username={username} theme={2} />
            <Theme activeTheme={newTheme} username={username} theme={3} />
            <Theme activeTheme={newTheme} username={username} theme={4} />
            <Theme activeTheme={newTheme} username={username} theme={5} />
            <Theme activeTheme={newTheme} username={username} theme={6} />
            <Theme activeTheme={newTheme} username={username} theme={7} />
            <Theme activeTheme={newTheme} username={username} theme={8} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Appearance;
