import React from 'react';
import { Bar } from 'react-chartjs-2';

const Chart = ({ reducedDailyStats }) => {
  const data = {
    datasets: [
      {
        label: 'Clicks',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#fff44f',
        borderColor: 'rgba(252, 193, 22, 1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(252, 193, 22, 1)',
        pointBackgroundColor: 'rgba(252, 193, 22)',
        pointBorderWidth: 5,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: 'rgba(252, 193, 22, 1)',
        pointHoverBorderColor: 'rgba(252, 193, 22, 1)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 5,
        data: reducedDailyStats,
      },
    ],
  };

  return (
    <>
      <Bar
        data={data}
        options={{
          spanGaps: true,
          legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 5,
              fontSize: 10,
            },
            position: 'bottom',
            align: 'start',
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'day',
                },

                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

export default Chart;
