import React from 'react';

const UserHeader = ({ username, avatar }) => {
  return (
        <header className="userContainer">
      <img className="avatarStyle" src={avatar} alt="user avatar" />
      <a className="user-name">
        {' '}
        <h2 style={{ padding: '15px 0px' }}>{`@${username}`}</h2>
      </a>
    </header>
  );
};

export default UserHeader;
