import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotFound from '../components/NotFound';
import ClipLoader from 'react-spinners/ClipLoader';
import '../themes.css';

const Confirmation = (props) => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  console.log(props.match.params);
  useEffect(
    () => {
      const value = {
        email: props.match.params.email,
        token: props.match.params.token,
      };
      const getLinks = () => {
        axios
          .post(`/users/confirmation`, value)
          .then((res) => {
            console.log(res);

            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setNotFound(true);
          });
      };
      getLinks();
    },
    props.match.params.email,
    props.match.params.token
  );

  // Return loading UI while waiting for GET request, then load user's page once the request goes through.
  // Return NotFound component if GET request does not find the searched username
  return loading && !notFound ? (
    <div className="green-container">
      <div className="loader" style={{ textAlign: 'center' }}>
        <ClipLoader
          sizeUnit={'px'}
          size={50}
          color={'rgb(31, 28, 28)'}
          loading={true}
        />
      </div>
    </div>
  ) : notFound ? (
    <NotFound />
  ) : (
    <div className="login">
      <div className="container-fluid row global">
        <div className="background-sign-in row">
          <div className="col-2"></div>
          <div className="col-7 align-self-center" style={{ marginTop: '0px' }}>
            <img
              alt=""
              className="logo"
              src="../../../../assets/logo-light.svg"
            />

            <app-login-form>
              <div className="">
                <h5>Email verified!</h5>
              </div>
            </app-login-form>
            <div className="col" style={{ paddingTop: '10px' }}>
              <button
                onClick={() => props.history.push('/login')}
                className="get-started"
                style={{ minWidth: '135px' }}
              >
                <span className="no-account">Login</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col Background-Right">
          <h1 style={{ marginTop: '38%', color: 'black' }}>
            <b>
              Influencer marketing with
              <br />
              guaranteed KPIs
            </b>
          </h1>
          <div className="mt-5" style={{ fontSize: '1.4vw' }}>
            <p>
              <b>
                Collaborate with influencers in a transparent and scalable way
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
