import React, { useState } from 'react';
import axios from 'axios';
import formValidation from '../components/FormValidation';
import inputErrors from '../components/InputErrors';
import '../themes.css';

const Passreset = (props) => {
  const initialState = {
    password: '',
  };
  console.log(props.match.params);
  const [res, setRes] = useState('');

  const axiosFunc = () => {
    const newval = {
      ...values,
      token: props.match.params.token,
      email: props.match.params.email,
    };
    axios
      .post(`/users/resetpassword`, newval)
      .then((res) => {
        setValues(initialState);
        setRes(res.data);
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data;
        setRes(error);
      });
  };
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    setValues,
  } = formValidation(initialState, inputErrors, axiosFunc);

  // Return loading UI while waiting for GET request, then load user's page once the request goes through.
  // Return NotFound component if GET request does not find the searched username
  return (
    <div className="login">
      <div className="container-fluid row global">
        <div className="background-sign-in row">
          <div className="col-2"></div>
          <div className="col-7 align-self-center" style={{ marginTop: '0px' }}>
            <img
              alt=""
              className="logo"
              src="../../../../assets/logo-light.svg"
            />

            <app-login-form>
              <div className="row">
                <div className="col">
                  <h5
                    style={{
                      marginLeft: ' -8%',
                      marginBottom: ' 3rem',
                      fontWeight: 'normal',
                    }}
                  >
                    {' '}
                    Password Reset{' '}
                  </h5>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row" style={{ paddingTop: '5%' }}>
                  <input
                    className={
                      errors.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                  {errors.password && (
                    <div className="container">
                      <div className="validation-error">{errors.password}</div>
                    </div>
                  )}
                  {res && (
                    <div className="container">
                      <div className="validation-error">{res}</div>
                    </div>
                  )}
                </div>
                <button
                  className="btn"
                  style={{ fontWeight: 'bold' }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update
                </button>
              </form>
            </app-login-form>
            {res === 'The Password is reset. Please log in.' && (
              <div className="col" style={{ marginTop: '10px' }}>
                <button
                  onClick={() => props.history.push('/login')}
                  className="get-started"
                  style={{ minWidth: '135px' }}
                >
                  <span className="no-account">Go To Login</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="col Background-Right">
          <h1 style={{ marginTop: '38%', color: 'black' }}>
            <b>
              Influencer marketing with
              <br />
              guaranteed KPIs
            </b>
          </h1>
          <div className="mt-5" style={{ fontSize: '1.4vw' }}>
            <p>
              <b>
                Collaborate with influencers in a transparent and scalable way
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passreset;
