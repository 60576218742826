import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const URLEverywhere = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('titleple')} />
      <div className="ulgetstart">
        <p>{t('ple02')}</p>
        <br />
        <ul>
          <li>{t('ple04')}</li>
          <li>{t('ple05')}s</li>
          <li>{t('ple06')}</li>
          <li>{t('ple07')}</li>
          <li>{t('ple08')}</li>

          <li>{t('ple10')}</li>
          <li>{t('ple11')}</li>
          <li>{t('ple12')}</li>
          <li>{t('ple13')}</li>
          <li>{t('ple14')}</li>
          <li>{t('ple15')}</li>
          <li>{t('ple16')}</li>
          <li>{t('ple17')}</li>
          <li>{t('ple18')}</li>
          <li>{t('ple21')}</li>
          <li>{t('ple22')}</li>
          <li>{t('ple23')}</li>
        </ul>
        {t('ple24')}
        <ul>
          <li>
            <Link to="/login"> {t('login')} </Link>
          </li>
          <li>{t('ple25')}</li>
          <li>{t('ple26')}</li>
        </ul>
        <br />
        <Link to="/help/start">
          <Button>{t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default URLEverywhere;
