import React from 'react';
import { Table } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

const TableClick = ({ dataLinks, dataUser }) => {
  const todayDate = new Date().toISOString().substring(0, 10);
  const linkColumns = [
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Username',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
    },
  ];

  const userColumns = [
    {
      title: 'Username',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Links',
      dataIndex: 'links',
      key: 'links',
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
    },
  ];

  return (
    <>
      <span>
        <h3>
          Most interactive links{' '}
          {dataLinks ? (
            <CSVLink
              data={dataLinks}
              filename={`${todayDate} Links LemmonLink Most Interactive.csv`}
            >
              <DownloadOutlined className="downloadButton" />
            </CSVLink>
          ) : null}
        </h3>
      </span>

      <br />
      <Table dataSource={dataLinks} columns={linkColumns} />
      <br />

      <span>
        <h3>
          Most interactive users
          {dataLinks ? (
            <CSVLink
              data={dataUser}
              filename={`${todayDate} Users LemmonLink Most Interactive.csv`}
            >
              <DownloadOutlined className="downloadButton" />
            </CSVLink>
          ) : null}
        </h3>
      </span>

      <br />
      <Table dataSource={dataUser} columns={userColumns} pagination={false} />
    </>
  );
};

export default TableClick;
