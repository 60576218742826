import React from "react"

const Social = ({social}) => {

    return (
        <div className="sc-fOKMvo bwgTtw">
        <span>
          {social && (
            <div className="social">
              <ul
                className="pull-center"
                style={{ marginRight: '50px' }}
              >
                <li>
                  <a
                    href={social.facebook}
                    target="_blank"
                    disabled={!social.facebook}
                  >
                    <svg
                      className={
                        social.facebook
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      viewBox="0 0 20 20"
                    >
                      <path d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={social.instagram}
                    target="_blank"
                    disabled={!social.instagram}
                  >
                    <svg
                      className={
                        social.instagram
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      viewBox="0 0 20 20"
                    >
                      <path d="M14.52,2.469H5.482c-1.664,0-3.013,1.349-3.013,3.013v9.038c0,1.662,1.349,3.012,3.013,3.012h9.038c1.662,0,3.012-1.35,3.012-3.012V5.482C17.531,3.818,16.182,2.469,14.52,2.469 M13.012,4.729h2.26v2.259h-2.26V4.729z M10,6.988c1.664,0,3.012,1.349,3.012,3.012c0,1.664-1.348,3.013-3.012,3.013c-1.664,0-3.012-1.349-3.012-3.013C6.988,8.336,8.336,6.988,10,6.988 M16.025,14.52c0,0.831-0.676,1.506-1.506,1.506H5.482c-0.831,0-1.507-0.675-1.507-1.506V9.247h1.583C5.516,9.494,5.482,9.743,5.482,10c0,2.497,2.023,4.52,4.518,4.52c2.494,0,4.52-2.022,4.52-4.52c0-0.257-0.035-0.506-0.076-0.753h1.582V14.52z"></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={social.twitter}
                    target="_blank"
                    disabled={!social.twitter}
                  >
                    <svg
                      className={
                        social.twitter
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      viewBox="0 0 20 20"
                    >
                      <path d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={social.youtube}
                    target="_blank"
                    disabled={!social.youtube}
                  >
                    <svg
                      className={
                        social.youtube
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      viewBox="0 0 50 50"
                      // style={{fill:"#000000"}}
                    >
                      <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={social.tiktok}
                    target="_blank"
                    disabled={!social.tiktok}
                  >
                    <svg
                      className={
                        social.tiktok
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      viewBox="0 0 512 512"
                    >
                      <path d="m195.19 512c-37.27 0-72.42-11.89-101.65-34.39-5.46-4.2-10.71-8.79-15.61-13.63-34.12-33.72-51.29-78.63-48.34-126.46 2.23-36.15 16.79-71 41.02-98.13 32.09-35.93 76.33-55.72 124.59-55.72 8.29 0 16.66.62 24.9 1.84l12.8 1.9v113.59l-19.71-6.51c-5.74-1.9-11.71-2.86-17.75-2.86-15.26 0-29.55 5.98-40.24 16.83-10.64 10.8-16.37 25.08-16.15 40.23.29 19.53 10.58 37.23 27.52 47.37 7.8 4.66 16.68 7.39 25.7 7.89 7.12.39 14.15-.54 20.91-2.77 23.11-7.63 38.64-29.07 38.64-53.34l.1-117.8v-230.04h108.56l.13 14.86c.06 6.73.75 13.46 2.05 19.98 5.1 25.64 19.48 48.49 40.49 64.33 18.65 14.07 40.91 21.5 64.35 21.5.55 0 .55 0 5.56.35l13.95.98v92 15l-5-.01h-9.97c-.08 0-.16 0-.25 0h-4.79c-.02 0-.05 0-.08 0-28.91 0-56-5.51-82.85-16.84-8-3.38-15.79-7.24-23.31-11.55l.34 147.81c-.18 43.72-17.46 84.73-48.65 115.56-25.31 25.01-57.25 40.99-92.37 46.19-8.19 1.22-16.56 1.84-24.89 1.84zm0-298.33c-39.59 0-75.89 16.23-102.21 45.7-19.76 22.13-31.64 50.54-33.46 79.99-2.4 39.05 11.62 75.73 39.49 103.27 4.03 3.98 8.34 7.75 12.82 11.2 23.94 18.43 52.77 28.17 83.36 28.17 6.86 0 13.75-.51 20.5-1.51 28.77-4.26 54.94-17.35 75.68-37.85 25.48-25.18 39.59-58.65 39.74-94.25l-.47-206.39 24.23 18.69c12.51 9.65 26.27 17.67 40.88 23.84 19.97 8.43 40.1 13.11 61.25 14.22v-48.45c-26.14-1.96-50.83-11.25-71.92-27.16-26.9-20.28-45.31-49.56-51.85-82.43-.7-3.53-1.27-7.1-1.69-10.7h-49.63v200.04l-.1 117.8c0 37.23-23.8 70.11-59.23 81.81-10.33 3.41-21.08 4.83-31.96 4.24-13.86-.76-27.5-4.95-39.46-12.1-25.93-15.51-41.68-42.68-42.12-72.67-.34-23.24 8.45-45.16 24.77-61.73 16.37-16.62 38.25-25.77 61.61-25.77 2.5 0 4.98.11 7.46.32v-48.07c-2.56-.14-5.13-.21-7.69-.21z" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href={social.twitch}
                    target="_blank"
                    disabled={!social.twitch}
                  >
                    <svg
                      className={
                        social.twitch
                          ? 'svg-icon'
                          : 'svg-icon disabled'
                      }
                      viewBox="0 0 512 512"
                    >
                      <path d="m202.214844 512 60-60h151l98.785156-98.785156v-353.214844h-443.214844l-68.785156 68.785156v383.214844h120v60zm-172.214844-90v-340.785156l51.214844-51.214844h400.785156v310.785156l-81.214844 81.214844h-151l-60 60h-39.785156v-60zm0 0" />
                      <path d="m181 443.214844 81.214844-81.214844h121l68.785156-68.785156v-233.214844h-362v302h91zm-61-111.214844v-242h302v190.785156l-51.214844 51.214844h-121l-38.785156 38.785156v-38.785156zm0 0" />
                      <path d="m241 165h30v92h-30zm0 0" />
                      <path d="m332 165h30v92h-30zm0 0" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </span>
      </div>
    )
}

export default Social