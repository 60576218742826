import React, { useContext } from 'react';
import axios from 'axios';
import '../themes.css';
import { Loader } from '../App';

const Theme = ({ activeTheme, username, theme }) => {
  const { loader } = useContext(Loader);
  const [rerender, reload] = loader;
  const token = localStorage.getItem('auth-token');
  const config = {
    headers: { 'auth-token': token },
  };

  const submit = () => {
    reload(true);
    axios
      .patch(`/users/${username}/theme`, { theme }, config)
      .then(() => {
        // window.location="/";
        reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isActive = () => {
    if (activeTheme === theme) {
      return 'active-theme';
    }
    return 'default-theme';
  };

  return (
    <div className={`${isActive()}`}>
      <div className={`box`} onClick={submit}>
        <div className={`theme-${theme}-thumb`}>
          <span className="dot"></span>
        </div>
      </div>
    </div>
  );
};

export default Theme;
