import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const LemmonlinkCommunity = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('__cmt')} />
      <div className="ulgetstart">
        <h3>
        {t('titlecmt')}
        </h3>
        <p>
        {t('cmt01')}
        </p>
        <p>{t('cmt02')}</p>
        <p>
        {t('cmt03')}
        </p>
        
        <Link to="/help/start">
          <Button>{t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default LemmonlinkCommunity;
