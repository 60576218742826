import React from 'react';
import { useTranslation } from 'react-i18next';
  

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="userContainer">
        <h1>{t("notFound")}</h1>
      </div>
    </div>
  );
};

export default NotFound;
