import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Stats from './Dashboard Components/Stats';
import Chart from './Dashboard Components/Chart';
import TableClicks from './Dashboard Components/TableClicks';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

const Reports = () => {
  let [globalReports, setGlobalReports] = useState({});
  const todayDate = new Date().toISOString().substring(0, 10);

  const getAllLinkStats = async () => {
    await axios.get('/admin/linkStats').then(({ data }) => {
      console.log(data)
      let top10Users = [];
      let top10Links = [];
      data.top10Links.forEach((data, index) => {
        top10Links.push({
          key: index,
          url: data.link.url,
          user: data.user,
          clicks: data.link.clicks,
        });
      });

      data.top10Users.forEach((data, index) => {
        top10Users.push({
          key: index,
          user: data.user,
          clicks: data.clicks,
          links: data.links,
        });
      });

      setGlobalReports({
        usersCount: data.usersCount,
        usersWithoutLinks: data.usersWithoutLinks,
        globalLinksCount: data.globalLinksCount,
        userMostClicks: data.userMostClicks,
        newLinks: data.newLinks,
        activeLinks: data.activeLinks,
        top10Links,
        top10Users,
        reducedDailyStats: data.reducedDailyStats,
      });
    })
  };

  useEffect(() => {
    getAllLinkStats();
  }, []);

  return (
    <>
      <br />
      <h3>Stats</h3>
      <Stats globalReports={globalReports} />
      <br />
      <span>
        <h3>
          Overall Clicks
          {globalReports.reducedDailyStats ? (
            <CSVLink
              data={globalReports.reducedDailyStats}
              filename={`${todayDate} Overall Clicks LemmonLink.csv`}
            >
              <DownloadOutlined className="downloadButton" />
            </CSVLink>
          ) : null}
        </h3>
      </span>
      <br />
      <Chart reducedDailyStats={globalReports.reducedDailyStats} />
      <br />
      <TableClicks
        dataLinks={globalReports.top10Links}
        dataUser={globalReports.top10Users}
      />
    </>
  );
};

export default Reports;
