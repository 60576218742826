import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AccountSetting from './AccountSetting';
import defaultAvatar from '../imgs/default.png';
import Modal from './Modal';

const Settings = ({ username }) => {
  const [userAvatar, setUserAvatar] = useState();
  const [user, setUser] = useState();
  const [dialog, setDialog] = useState(false);
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem('auth-token');
  const languageSelected = localStorage.getItem('language');

  const config = {
    headers: { 'auth-token': token },
  };

  const changeLanguage = (lng) => {
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng);
  };

  const removeAvatar = () => {
    axios
      .patch(`/users/${username}/defaultAvatar`, {}, config)
      .then((res) => {
        setUserAvatar(defaultAvatar);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const logOut = () => {
    localStorage.removeItem('auth-token');
    window.location = '/';
  };
  const deleteAccount = () => {
    axios
      .patch(`/users/${username}/deactive`, {}, config)
      .then((res) => {
        if (res.data) logOut();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [newTheme, setNewTheme] = useState(0);


  const rerenderAvatar = (avatar) => {
    setUserAvatar(avatar);
  };

  const getTheme = () => {
    axios
      .get(`/users/admin`, config)
      .then((res) => {
        setUser({ username: res.data.username, email: res.data.email });
        setNewTheme(res.data.theme);
        setUserAvatar(res.data.avatar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
   getTheme();
  }, [newTheme]);

  return (
    <>
      <section className=" section settings-container userpreview">
        {user && (
          <div className="contact">
            <h3>{t("myInfo")}</h3>
            <div className="section__inner">
              <div className="billing-name">
                <label className="active-input">
                  <input
                    type="text"
                    className="field"
                    name="name"
                    value={user.username}
                    disabled
                  />
                  <span>
                    <span>{t("username")}</span>
                  </span>
                </label>
              </div>
              <div className="billing-email">
                <label className="active-input">
                  <input
                    type="text"
                    className="field"
                    name="email"
                    value={user.email}
                    disabled
                  />
                  <span>
                    <span>Email</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}

        <div className=" section settings-container userpreview">
          <h3>{t("language")}</h3>
          <div  className="section__inner">
          <ReactFlagsSelect
            defaultCountry={
              languageSelected ? languageSelected.toUpperCase() : 'US'
            }
            fullWidth={false}
            alignOptionsToRight
            countries={['US', 'ES', 'PT']}
            customLabels={{
              US: 'English',
              ES: 'Spanish',
              PT: 'Portuguese',
            }}
            onSelect={(countryCode) =>
              changeLanguage(countryCode.toLowerCase())
            }
          />
          </div>
        </div>

        <div className="avatar-container">
          <div className="section section--medium profile">
            <h3>{t("profile")}</h3>
            <div className="section__inner">
              <div id="setting-page-options" className="setting">
                <div id="CustomAvatar">
                  <Modal
                    username={username}
                    avatar={userAvatar}
                    rerender={rerenderAvatar}
                    removeAvatar={removeAvatar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AccountSetting username={username} />

      <div className="section section--legacy delete">
        <h3>{t("dangerZone")}</h3>
        <div className="section__inner">
          <div className="action delete-account">
            <input
              className="button red"
              type="button"
              value={t("deleteAccount")}

              onClick={() => setDialog(true)}
            />
          </div>
        </div>
      </div>
      {dialog && (
        <div className="dialog dialog--delete-account dialog--warning dialog-active">
          <div className="dialog__inner">
            <div className="dialog__header">
            {t("sure")}

              <div className="x" onClick={() => setDialog(false)}>
                ×
              </div>
            </div>
            <div className="dialog__body">
              <p>
             {t("deleteAccountMsg1")}

              </p>
              <p>
              {t("deleteAccountMsg2")}
 (@
                {user.username}) {t("deleteAccountMsg3")}

                <br/>
                <a href={`${window.location.origin}/${user.username}`}>
                {t("deleteAccountMsg4")}

                </a>
                .
              </p>
              <p className="buttons">
                <input
                  className="button outline"
                  type="button"
                  value={t("sure")}

                  onClick={() => setDialog(false)}
                />
                <input
                  className="button red"
                  type="button"
                  value={t("yesDelete")}

                  onClick={() => deleteAccount()}
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;

{
  /* <div className="billing-name">
<span>
<span>Language</span>
</span>

<ReactFlagsSelect
defaultCountry={
languageSelected ? languageSelected.toUpperCase() : 'US'
}
fullWidth={false}
alignOptionsToRight
countries={['US', 'ES', 'PT']}
customLabels={{
US: 'English',
ES: 'Spanish',
PT: 'Portuguese',
}}
onSelect={(countryCode) =>
changeLanguage(countryCode.toLowerCase())
}
/>

</div> */
}
