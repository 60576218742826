import React from 'react';
import { useTranslation} from "react-i18next"
import logo from '../../assests/images/logo.png';
import { Link } from 'react-router-dom';

const Navbar = ({ title, description }) => {
  const { t } = useTranslation();
  return (
      <div className="header_area help">
        <div className="navbar">
          <div className="navbar-header">
            <div className="container">
              <div className="basic-wrapper">
                <a
                  rel="noopener"
                  className="btn responsive-menu pull-right"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  <i className="icon-menu-1" />
                </a>
                <Link className="navbar-brand" to="/">
                  <img
                    src={logo}
                    alt="Lemmonet Influencers"
                    data-src={logo}
                    data-ret={logo}
                    className="retina"
                    style={{ display: 'inline' }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <nav className="collapse navbar-collapse pull-right"></nav>
        <div className="help element-banner2">
          <div className="row">
            <div className="col-sm-2 col-xs-12" />
            <div className="col-sm-8 col-xs-12 text-center">
              <h1
                className="text-title animated fadeInLeft delay-01"
                data-animation="fadeInLeft"
                data-animation-delay="01"
              >
                {title}
              </h1>
              <div
                className="text-resume animated fadeIn delay-02"
                data-animation="fadeIn"
                data-animation-delay="02"
              >
                <div className="text-line">{description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
  );
};

export default Navbar;
