import React from 'react';
import { useTranslation } from 'react-i18next';
import Cards from './Cards';
import Navbar from './Navbar';
import Footer from '../../components/Footer';
import faq from '../../assests/images/faq.svg';
import ticket from '../../assests/images/ticket.svg';
import getstarted from '../../assests/images/getstarted.svg';

const Help = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('navbanner')} description={t('navbanner2')} />
      <div className="area-full">
        <div className="light-wrapper">
          <div className="container inner cards">
            <Cards
              title={t('getStartedCard')}
              description={t('getStartedCard2')}
              link="/help/start"
              icon={getstarted}
            />
            <Cards
              title={t('contactCard')}
              description={t('contactCard2')}
              link="/help/ticket"
              icon={ticket}
            />
            <Cards title="FAQ" link="/help/faq" icon={faq} />
          </div>
        </div>
        <Footer text={`Lemmonet Copyright 2020.`} />
      </div>
    </div>
  );
};

export default Help;
