import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FormValidation from './FormValidation';
import LinkAnalysis from './LinkAnalysis';
import inputErrors from './InputErrors';
import classnames from 'classnames';
import { Loader } from '../App';
import { DeleteOutlined, LineChartOutlined } from '@ant-design/icons';

const EditableLink = ({
  id,
  visible,
  linkTitle,
  shorturl,
  url,
  username,
  moveUp,
  moveDown,
}) => {
  const { loader } = useContext(Loader);
  const [rerender, reload] = loader;
  const [initialState, setInitialState] = useState({
    _id: id,
    linkTitle,
    url,
    visible,
    shorturl,
  });
  const [opendelete, setDelete] = useState(false);
  const [openAnalysis, setAnalysis] = useState(false);
  const [linkStats, setLinkStats] = useState({
    day: 0,
    week: 0,
    month: 0,
    total: 0,
  });
  const [editTitle, setEditTitle] = useState(false);
  const [editURL, setEditURL] = useState(false);
  const [added, setAdded] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('auth-token');
  const config = {
    headers: { 'auth-token': token },
  };

  const axiosFunc = () => {
    reload(true);
    let httpRegex = new RegExp(`^(https?:\/\/)` || `^(http?:\/\/)`);

    if (!httpRegex.test(url)) {
      url = `https://${url}`;
    }
    axios
      .patch(`/users/${username}/editlink`, values, config)
      .then((res) => {
        if (editTitle === true) {
          toggleEditTitle();
        }
        if (editURL === true) {
          toggleEditURL();
        }
        setAdded(true);
        reload(false);
        // Notify user that post was successful, then removes the notification after 3 sec
        setTimeout(() => {
          setAdded(false);
        }, 1500);
      })
      .catch((err) => {
        const error = err.response.data;
        setErrors(error);
        reload(false);
      });
  };

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setErrors,
  } = FormValidation(initialState, inputErrors, axiosFunc);

  const deleteLink = () => {
    reload(true);
    axios
      .patch(`/users/${username}/deletelink`, { _id: id }, config)
      .then(() => {
        setDelete(false);
        reload(false);
      })
      .catch((err) => {
        reload(false);
        console.log(err);
      });
  };

  const toggleEditTitle = () => {
    setEditTitle(!editTitle);
  };

  const toggleEditURL = () => {
    setEditURL(!editURL);
  };
  const onFocus = (e) => {
    e.currentTarget.classList.add('focused');
    e.currentTarget.querySelector('input').focus();
  };

  const removeFocus = (e) => {
    e.currentTarget.classList.remove('focused');
    if (initialState.linkTitle !== values.linkTitle) {
      handleSubmit();
    }
    if (url !== values.url) {
      let httpRegex = new RegExp(`^(https?:\/\/)`);
      if (!httpRegex.test(values.url)) {
        values.url = `https://${values.url}`;
      }
      axios
        .post(
          `https://lemmonetinfluencersbackendmaster.azurewebsites.net/api/generateShortUrl?url=${values.url}&service=firebase`
        )
        .then((res) => {
          values.shorturl = res.data.shortUrl;
          handleSubmit();
        })
        .catch((err) => console.log(err));
    }
  };

  const getAnalysis = (url) => {
    axios
      .patch('/firebase/dynamiclinks', { link: encodeURIComponent(url) })
      .then((res) => {
        if (Object.keys(res.data).length > 0)
          setLinkStats({
            day: res.data.day,
            week: res.data.week,
            month: res.data.month,
            total: res.data.total,
          });
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  };
  return (
    <>
      <div
        className={classnames(
          'link',
          { incomplete: values.url === '' || values.linkTitle === '' },
          { unsafe: errors.url }
        )}
      >
        <div className="link__inner">
          <div className="link__button">
            {moveUp}
            {moveDown}
          </div>
          <div className="link-content">
            <div
              className="link-field link-title placeholder"
              onClick={(e) => onFocus(e)}
              onMouseLeave={(e) => removeFocus(e)}
            >
              <input
                type="text"
                placeholder={values.linkTitle}
                name="linkTitle"
                value={values.linkTitle}
                onChange={handleChange}
                placeholder={'Title'}
              />
              <span tabIndex="0">{values.linkTitle}</span>
              <i>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <path d="M2.5,6.67188,8.46477.70711a1,1,0,0,1,1.41421,0L11.29289,2.121a1,1,0,0,1,0,1.41421L5.32813,9.5ZM4.32813,10.5,0,12,1.5,7.67188Z"></path>
                </svg>
              </i>
            </div>
            <div
              className={classnames('link-field link-url placeholder', {
                'validation-failed': errors.url,
              })}
              onClick={(e) => onFocus(e)}
              onMouseLeave={(e) => removeFocus(e)}
            >
              <input
                type="text"
                name="url"
                value={values.url}
                onChange={handleChange}
                placeholder={values.url}
              />
              <span tabIndex="0">{values.url}</span>
              <i>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <path d="M2.5,6.67188,8.46477.70711a1,1,0,0,1,1.41421,0L11.29289,2.121a1,1,0,0,1,0,1.41421L5.32813,9.5ZM4.32813,10.5,0,12,1.5,7.67188Z"></path>
                </svg>
              </i>
            </div>
            <label
              className={classnames('link-field link-active', {
                checked: values.visible,
              })}
            >
              <input
                type="checkbox"
                name="visible"
                value={values.visible}
                onChange={handleChange}
              />
            </label>
            <div className="link__tab__navs">
              <a
                className="button"
                onClick={() => {
                  getAnalysis(values.shorturl);
                  setDelete(false);
                  setAnalysis(true);
                }}
                style={{ backgroundColor: 'white' }}
              >
                <LineChartOutlined style={{ color: 'black' }} />
              </a>
              <a
                className="button link__tab__nav--delete"
                onClick={() => {
                  setAnalysis(false);
                  setDelete(true);
                }}
                style={{ backgroundColor: 'white' }}
              >
                <DeleteOutlined style={{ color: 'black' }} />
              </a>
            </div>
          </div>
          <div
            className="link__tab__panels"
            style={{ height: opendelete ? '137px' : '0px' }}
          >
            <div
              className="link__tab__panel"
              style={{ display: opendelete ? 'block' : 'none' }}
            >
              <div className="panel__header">
                <h5>{t('deleteLink')}</h5>
                <a className="x" data-close onClick={() => setDelete(false)}>
                  ×
                </a>
              </div>
              <div
                className="panel__body"
                style={{ display: opendelete ? 'block' : 'none' }}
              >
                <p>{t('deleteLinkMsg')}</p>
                <div className="buttons">
                  <button
                    className="button grey"
                    onClick={() => setDelete(false)}
                  >
                    {t('cancel')}
                  </button>
                  <button className="button" onClick={deleteLink}>
                    {t('delete')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="link__tab__panels"
            style={{
              height: added || errors.url ? '30px' : '0px',
              display: added || errors.url ? 'block' : 'none',
            }}
          >
            <div
              className="link__tab__panel"
              style={{ display: added ? 'block' : 'none' }}
            >
              <div
                className="panel__header"
                style={{ backgroundColor: '#4cd775' }}
              >
                <h5>{t('updatedLink')}</h5>
                <a className="x" data-close onClick={() => setAdded(false)}>
                  ×
                </a>
              </div>
            </div>
            <div
              className="link__tab__panel"
              style={{ display: errors.url ? 'block' : 'none' }}
            >
              <div
                className="panel__header"
                style={{ backgroundColor: '#ff1731bf' }}
              >
                <h5>{errors.url}</h5>
                <a
                  className="x"
                  data-close
                  onClick={() => setErrors({ errors: { url: '' } })}
                >
                  ×
                </a>
              </div>
            </div>
          </div>
          <div
            className="link__tab__panels"
            style={{
              height: openAnalysis ? '137px' : '0px',
              display: openAnalysis ? 'block' : 'none',
            }}
          >
            <LinkAnalysis
              linkStats={linkStats}
              setAnalysis={setAnalysis}
              openAnalysis={openAnalysis}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditableLink;
