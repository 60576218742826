import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

const UserList = ({}) => {
  const [searching, setSearching] = useState(false);
  const [allUsers, setUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const todayDate = new Date().toISOString().substring(0, 10);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mail',
      dataIndex: 'Mail',
      key: 'Mail',
    },
    {
      title: 'Verified',
      dataIndex: 'verified',
      key: 'verified',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const searchUsers = (username) => {
    if (username === '') setSearching(false);
    else {
      setSearching(true);
      let searchUser = allUsers.filter((user) => {
        return user.name.toLowerCase().includes(username.toLowerCase());
      });
      setSearchedUsers(searchUser);
    }
  };

  const getAllUser = async () => {
    const users = [];
    await axios
      .get(`/users`)
      .then(({ data }) => {
        data.forEach((user, index) => {
          users.push({
            key: index,
            name: user.username.toString(),
            Mail: user.email.toString(),
            verified: user.isVerified.toString(),
            active: user.active.toString(),
            socialMedia: user.socialMedia,
            type: user.admin ? 'Admin' : 'User',
          });
        });
      })
      .catch((err) => {
        console.log(err.response);
      });

    setUsers(users);
  };

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <div
      className="link-list-group"
      id="link-list-group"
      style={{ marginTop: '10px' }}
    >
      <div className="billing-name">
        <label className="active-input" style={{ margin: '30px 10px' }}>
          <input
            type="text"
            className="field"
            name="name"
            onChange={(e) => searchUsers(e.target.value)}
          />
          <span>
            <span>Search</span>
          </span>
        </label>
      </div>
      <h3>
        User List
        <CSVLink
          data={allUsers}
          filename={`Lemmonlink User List ${todayDate}.csv`}
        >
          <DownloadOutlined className="downloadButton" />
        </CSVLink>
      </h3>
      <br />
      {searching ? (
        <Table dataSource={searchedUsers} columns={columns} />
      ) : (
        <Table dataSource={allUsers} columns={columns} />
      )}
    </div>
  );
};

export default UserList;
