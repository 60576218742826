import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logofooter from '../assests/images/logo-footer.png';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer black-wrapper">
      <div className="container inner">
        <div className="text-right">
          <p className="pull-left" >
            <img src={logofooter} className="copy" alt="Lemmonet" />
            {`Lemmonet Copyright 2020. ${t('footer')}`}
          </p>
          <ul>
            <li style={{ listStyleType: "none"}}>
              <a target="_blank" href="https://www.lemmonet.com/influencers/#">
                <p className="text-social">{t('influencer')}</p>
              </a>
            </li>
            <li  style={{ listStyleType: "none"}} >
              <a href="https://blog.lemmonet.com/blog-en" target="_blank">
                <p className="text-social">Blog</p>
              </a>
            </li>
            <li  style={{ listStyleType: "none"}}>
              <a target="_blank" href="https://www.lemmonet.com/influencers/#">
                <p className="text-social">{t('caseStudies')}</p>
              </a>
            </li>
            <li  style={{ listStyleType: "none"}}>
              <Link to="/help/ticket">
                <p className="text-social">{t('contact')}</p>
              </Link>
            </li>
            <li  style={{ listStyleType: "none"}}>
              <Link to="/login">
                <p className="text-social">{t('login')}</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
