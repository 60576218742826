import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './style/app.css';
import './style/home.css';
import './style/help.css';
import './style/bootstrap.css';
import './style/animation.css';
import './style/login.css';
import './style/admin-panel/admin.css';
import './style/admin-panel/main.css';
import './style/admin-panel/adminNav.css';
import './style/admin-panel/service.css';
import './style/theme/all-themes.css';
import './style/fontawesome.min.css';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
         <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
