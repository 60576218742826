import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import {Link} from "react-router-dom"

const GetStart = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('getStartedCard')}/>
      <div>
      <ul class="ulgetstart">
        <li class="articles__item">
          <Link to="/help/verifyaccount">{t('___VERIFY-ACCOUNT')}</Link>
        </li>

        <li class="articles__item">
          <Link to="/help/createaccount">
          {t('__C.LML')}
          </Link>
        </li>

        <li class="articles__item">
          <Link to="/help/createlink">
          {t('__L.M.L')}
          </Link>
        </li>

        <li class="articles__item">
          <Link to="/help/lemmonlinkurl">
          {t('__ple')}
          </Link>
        </li>

        <li class="articles__item">
          <Link to="/help/updatesettings">  {t('__uat')}</Link>
        </li>

        <li class="articles__item">
          <Link to="/help/analytics">  {t('__als')}</Link>
        </li>

        <li class="articles__item">
          <Link to="/help/lemmonlinkcommunity">
          {t('__cmt')}
          </Link>
        </li>
      </ul>
      </div>
      <Footer />
    </div>
  );
};

export default GetStart;
