import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import {Link} from "react-router-dom"

const VerifyAccount = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title= {t('titleva')} />
      <div className="ulgetstart">
      <p>
        <span>
        {t('va01')} &nbsp;
        </span>
      </p>
      <ol>
        <li>
          <span>
            <span>
            {t('va03')}
              <a href="www.lemmonlink.com/signup"> {t('here')}</a>.
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
            {t('va05')}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
            {t('va06')}
            </span>
          </span>
        </li>
      </ol>
      <p>
        <span>
          <span>
            <strong> {t('va07')}</strong>
          </span>
        </span>
      </p>
      <ul>
        <li>
          <span>
            <span>
            {t('va08')}<a href="www.lemmonlink.com/admin"> {t('here')}</a>  {t('va09')}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
            {t('va10')}
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
            {t('va11')}
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
            {t('va12')}
            </span>
          </span>
        </li>
      </ul>
      <br/>
      <Link to="/help/start">
      <Button> {t('goback')}</Button>
      </Link>
      </div>
      <Footer />
    </div>
  );
};

export default VerifyAccount;
