const inputErrors = (values) => {

  let errors = {};

  const names = Object.keys(values);

  names.forEach((name) => {
    switch (name) {
      case 'username':
        if (!values.username) {
          errors.username = 'Required username.';
        }
        break;
      case 'email':
        if (!values.email) {
          errors.email = 'Required Email.';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address.';
        }
        break;
      case 'password':
        if (!values.password) {
          errors.password = 'Required Password.';
        } else if (values.password.length < 6) {
          errors.password = 'Password must be at least 6 characters.';
        }
        break;
      case 'newpassword':
        if (!values.newpassword) {
          errors.newpassword = 'Required Password.';
        } else if (values.newpassword.length < 6) {
          errors.newpassword = 'Password must be at least 6 characters.';
        }
        break;
      case 'url':
        if (!values.url) {
          errors.url = 'Required URL.';
        } else if (
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.url
          )
        ) {
          errors.url = 'Invalid URL.';
        }
        break;
      case 'linkTitle':
       
        if (!values.linkTitle) {
          errors.linkTitle = 'Required Title.';
        }
        break;
      case 'facebook':
        if (!values.facebook) {
        } else if (
          !values.facebook.includes("facebook.com/") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.facebook
          ) 
      ) {
                 errors.facebook = 'Invalid URL.';
             }
        break;
      case 'instagram':
        if (!values.instagram) {
        } else if (
          !values.instagram.includes("instagram.com/") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.instagram
          )
        ) {
          errors.instagram = 'Invalid URL.';
        }
        break;
      case 'twitter':
        if (!values.twitter) {
        } else if (
          !values.twitter.includes("twitter.com/") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.twitter
          )
        ) {
          errors.twitter = 'Invalid URL.';
        }
        break;
      case 'youtube':
        if (!values.youtube) {
        } else if (
          !values.youtube.includes("youtube.com/") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.youtube
          )
        ) {
          errors.youtube = 'Invalid URL.';
        }
        break;
      case 'tiktok':
        if (!values.tiktok) {
        } else if (
          !values.tiktok.includes("tiktok.com/@") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.tiktok
          )
        ) {
          errors.tiktok = 'Invalid URL.';
        }
        break;
      case 'twitch':
        if (!values.twitch) {
        } else if (
          !values.twitch.includes("twitch.tv/") ||
          !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
            values.twitch
          )
        ) {
          errors.twitch = 'Invalid URL.';
        }
        break;
      default:
        break;
    }
  });

  return errors;
};

export default inputErrors;
