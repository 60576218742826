import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LinkContainer from './LinkContainer';
import UserLinkTree from './UserLinkTree';
import Appearance from './Appearance';
import classNames from 'classnames';
import Social from './Social';
import SocialAdd from './SocialAdd';
import AdminSidebar from './AdminSidebar';
import Settings from './Settings';
import UserList from '../components/admin/UserList';
import Reports from '../components/admin/Reports';
import 'antd/dist/antd.css';
import helpIcon from '../imgs/help.svg';

const Admin = ({ username }) => {
  const [tab, setTab] = useState(0);
  const [size, setSize] = useState([0, window.innerHeight * 0.001]);
  const [socialopen, setSocialopen] = useState(false);
  const [userData, setUserData] = useState({});
  const { t } = useTranslation();

  let width = window.matchMedia('(min-width: 768px)').matches;

  const logOut = () => {
    localStorage.removeItem('auth-token');
    window.location = '/';
  };

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight * 0.001]);
  }

  useEffect(() => {
    const getUserLinks = () => {
      axios
        .get(`/users/${username}`)
        .then(({ data }) => {
          setUserData(data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    getUserLinks();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className="admin-panel">
      <div className={tab === 3 || tab === 4 ? null : 'main-wrap'}>
        <AdminSidebar username={username} />

        <div>
          <div className="fJrVhw">
            <a
              className={classNames('ixEuKd', { active: tab == 0 })}
              onClick={() => setTab(0)}
            >
              {t('links')}
            </a>

            <a
              className={classNames('ixEuKd', { active: tab == 1 })}
              onClick={() => setTab(1)}
            >
              {t('appareance')}
            </a>
            <a
              className={classNames('ixEuKd', { active: tab == 2 })}
              onClick={() => {
                setTab(2);
              }}
            >
              {t('settings')}
            </a>
            {userData.admin ? (
              <a
                className={classNames('ixEuKd', { active: tab == 3 })}
                onClick={() => {
                  setTab(3);
                }}
              >
                Users
              </a>
            ) : null}

            {userData.admin ? (
              <a
                className={classNames('ixEuKd', { active: tab == 4 })}
                onClick={() => {
                  setTab(4);
                }}
              >
                Reports
              </a>
            ) : null}
            <a className="ixEuKd navbarRight" href="/help" target="_blank">
              <img className="helpIcon" src={helpIcon}></img>
            </a>

            <a className="ixEuKd navbarRight " onClick={logOut}>
              {t('logout')}
            </a>
          </div>
        </div>
        <section className="main admin has-toolbar">
          <div className="sc-bnXvFD fDOEsD">
            <header
              className="sc-dymIpo fiqclI"
              onClick={() => setSocialopen(!socialopen)}
            >
              <div>
                <span>{t('social')}</span>
                <Social social={userData.socialMedia} />
              </div>
            </header>
            <div
              className="sc-cpmLhU EflSd"
              style={{
                overflow: 'hidden',
                height: socialopen ? '600px' : '0px',
                transition: 'height 1s',
              }}
            >
              <div>
                <div
                  className="sc-gFaPwZ bZqHmk"
                  style={{ padding: '0px 20px' }}
                >
                  <div className="sc-dUjcNx gqsIyQ">
                    <SocialAdd username={username} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            className="action-area section__inner  ps-theme-default"
            style={{ display: 'block' }}
          >
            <section className="primary">
              <div className="main">
                {tab == 0 && <LinkContainer username={username} />}
                {tab == 1 && <Appearance username={username} />}
                {tab == 2 && <Settings username={username} />}
                {tab == 3 && <UserList />}
                {tab == 4 && <Reports />}
              </div>
            </section>
          </section>
        </section>
        {tab === 3 || tab === 4 ? null : (
          <div>
            <section className="secondary admin has-toolbar">
              <div className="toolbar">
                <div className="toolbar__inner">
                  <span className="toolbar__label">Lemmon Link</span>
                  <div className="toolbar__url">
                    <a
                      href={`${window.location.origin}/${username}`}
                      target="_blank"
                      rel="noreferrer"
                    >{`${window.location.origin}/${username}`}</a>
                  </div>
                </div>
              </div>
              <section
                className="preview open section__inner "
                style={{ display: width ? '' : tab == 0 ? 'block' : 'none' }}
              >
                <div className="inner">
                  <div
                    className="preview-wrap"
                    id="preview-wrap"
                    style={{
                      transform: `scale(${size[1]}) translateX(-50%) translateY(-50%) translate3d(0px, 0px, 0px)`,
                    }}
                  >
                    <div
                      className="preview-inner ps-container ps-theme-default"
                      style={{ borderRadius: '35px' }}
                    >
                      <UserLinkTree username={username} />
                    </div>
                    <span className="loader"></span>
                  </div>
                </div>
              </section>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
