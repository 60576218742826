import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import formValidation from './FormValidation';
import inputErrors from './InputErrors';
import classnames from 'classnames';

const AccountSetting = ({ username }) => {
  const { t } = useTranslation();
  const initialState = {
    password: '',
    newpassword: '',
  };
  const [res, setRes] = useState('');
  const token = localStorage.getItem('auth-token');
  const config = {
    headers: { 'auth-token': token },
  };

  const axiosFunc = () => {
    axios
      .patch(`/users/${username}/updatepass`, values, config)
      .then((res) => {
        setValues(initialState);
        setRes(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        setRes(error);
      });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    setValues,
  } = formValidation(initialState, inputErrors, axiosFunc);
  return (
    <form className="section section--legacy" onSubmit={handleSubmit}>
      <div className="contact">
        <h3>{t("changePassword")}</h3>
        <div className="section__inner">
          <div className="billing-name">
            <label
              className={classnames('active-input', { error: errors.password })}
            >
              <input
                type="password"
                className="field"
                name="password"
                value={values.password}
                onChange={handleChange}
              />
              <span>
                <span>{t("oldPassword")}</span>
              </span>
            </label>
            {errors.password && (
              <div
                data-active-input-error
                data-name-errors
                className={classnames({ active: errors.password })}
              >
                {errors.password}
              </div>
            )}
          </div>
          <div className="billing-email">
            <label
              className={classnames('active-input', {
                error: errors.newpassword,
              })}
            >
              <input
                type="password"
                className="field"
                name="newpassword"
                value={values.newpassword}
                onChange={handleChange}
              />
              <span>
                <span>{t("newPassword")}</span>
              </span>
            </label>
            {errors.newpassword && (
              <div
                data-active-input-error
                data-email-errors
                className={classnames({ active: errors.newpassword })}
              >
                {errors.newpassword}
              </div>
            )}
            {res && <div id="email-verification-message">{res}</div>}
          </div>
        </div>
      </div>

      <div className="submit">
        <input
          className="button large yellow disabled"
          data-user-edit-submit=""
          type="submit"
          value={t("saveDetails")}

        />
      </div>
    </form>
  );
};
export default AccountSetting;
