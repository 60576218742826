import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const UpdateAvatarTheme = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('titleuat')} description={t('titleuat2')} />
      <div className="ulgetstart">
        <h4>  {t('uat01')}</h4>
        <ul>
          <li> {t('uat02')}</li>
          <li> {t('uat03')}</li>
          <li> {t('uat04')}</li>
          <li> {t('uat05')}</li>
          <li> {t('uat06')}</li>
        </ul>

        <p> {t('uat07')}</p>
        <p>
        {t('uat08')}
        </p>
        <p>
        {t('uat09')}
        </p>
        <Link to="/help/start">
          <Button> {t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateAvatarTheme;
