import React, { useState, useEffect } from 'react';
import Admin from '../components/Admin';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

const State = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('auth-token');
  const config = {
    headers: { 'auth-token': token, 'Content-Type': 'application/json' },
  };

  const getName = () => {
    axios
      .get(`/users/admin`, config)
      .then((res) => {
        setUsername(res.data.username);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getName();
  }, []);

  return loading ? (
    <div className="green-container">
      <div className="loader" style={{ textAlign: 'center' }}>
        <ClipLoader
          sizeUnit={'px'}
          size={50}
          color={'rgb(31, 28, 28)'}
          loading={true}
        />
      </div>
    </div>
  ) : (
    <Admin username={username} />
  );
};

export default State;
