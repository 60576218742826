import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import formValidation from './FormValidation';
import inputErrors from './InputErrors';
import classnames from 'classnames';
import isURL from 'validator/lib/isURL';
import { Loader } from '../App';

const NewLink = ({ username }) => {
  const { t } = useTranslation();
  const [added, setAdded] = useState(false);
  const { loader } = useContext(Loader);
  const [rerender, reload] = loader;
  const initialState = {
    linkTitle: '',
    url: '',
    visible: false,
  };

  const token = localStorage.getItem('auth-token');
  const config = {
    headers: { 'auth-token': token },
  };

  const axiosFunc = () => {
    reload(true);
    let httpRegex = new RegExp(`^(https?:\/\/)`);

    if (!httpRegex.test(values.url)) {
      values.url = `https://${values.url}`;
    }
    axios
      .post(
        `https://lemmonetinfluencersbackendqa.azurewebsites.net/api/generateShortUrl?url=${values.url}&service=firebase`
      )
      .then((res) => {
       
        values.shorturl = res.data.shortUrl;
        axios
          .patch(`/users/${username}`, values, config)
          .then((res) => {
            // Reset to initial state
            setAdded(true);
            setValues(initialState);
            reload(false);
            setTimeout(() => {
              setAdded(false);
            }, 1500);
            // Notify user that post was successful, then removes the notification after 1.5 sec

              })
          .catch((err) => {
            reload(false);
            const error = err.response.data;
            setErrors(error);
          });
      })
      .catch((err) => {
        reload(false);
        console.log(err);
      });
  };

  const onFocus = (e) => {
    e.currentTarget.classList.add('focused');
    e.currentTarget.querySelector('input').focus();
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setValues,
    setErrors,
  } = formValidation(initialState, inputErrors, axiosFunc);

  return (
    <>
      <div
        className={classnames(
          'link',
          { incomplete: values.url === '' || values.linkTitle === '' },
          { unsafe: !isURL(values.url) }
        )}
      >
        <div className="link__inner">
          <div className="link-content">
            <div
              className="link-field link-title placeholder"
              onClick={(e) => onFocus(e)}
            >
              <input
                type="text"
                placeholder={values.linkTitle}
                name="linkTitle"
                value={values.linkTitle}
                onChange={handleChange}
                placeholder="Title"
              />
              <span tabIndex="0">
                {values.linkTitle ? values.linkTitle : 'Title'}
              </span>
              <i>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <path d="M2.5,6.67188,8.46477.70711a1,1,0,0,1,1.41421,0L11.29289,2.121a1,1,0,0,1,0,1.41421L5.32813,9.5ZM4.32813,10.5,0,12,1.5,7.67188Z"></path>
                </svg>
              </i>
            </div>
            <div
              className={classnames('link-field link-url placeholder', {
                'validation-failed': errors.url,
              })}
              onClick={(e) => onFocus(e)}
            >
              <input
                type="text"
                name="url"
                value={values.url}
                onChange={handleChange}
                placeholder={"http://url"}
              />
              <span tabIndex="0">{values.url ? values.url : 'http://url'}</span>
              <i>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <path d="M2.5,6.67188,8.46477.70711a1,1,0,0,1,1.41421,0L11.29289,2.121a1,1,0,0,1,0,1.41421L5.32813,9.5ZM4.32813,10.5,0,12,1.5,7.67188Z"></path>
                </svg>
              </i>
            </div>
            <div className="link__tab__navs">
              <a
                className="link__tab__nav--delete button yellow"
                onClick={handleSubmit}
              >
               {t("addLink")}

              </a>
            </div>
          </div>
          <div
            className="link__tab__panels"
            style={{
              height: added || errors.url || errors.linkTitle ? '30px' : '0px',
            }}
          >
            <div
              className="link__tab__panel"
              style={{ display: added ? 'block' : 'none' }}
            >
                          <div
                className="panel__header"
                style={{ backgroundColor: '#4cd775' }}
              >
                <h5>{t("newLinkAdded")}
</h5>
                <a className="x" data-close>
                  ×
                </a>
              </div>
            </div>
            <div
              className="link__tab__panel"
              style={{ display: errors.url ? 'block' : 'none' }}
            >
              {/* use active class to activate tab */}
              <div
                className="panel__header"
                style={{ backgroundColor: '#ff1731bf' }}
              >
                <h5>{errors.url}</h5>
                <a className="x" data-close>
                  ×
                </a>
              </div>
            </div>
            <div
              className="link__tab__panel"
              style={{ display: errors.linkTitle ? 'block' : 'none' }}
            >
              {/* use active class to activate tab */}
              <div
                className="panel__header"
                style={{ backgroundColor: '#ff1731bf' }}
              >
                <h5>{errors.linkTitle}</h5>
                <a className="x" data-close>
                  ×
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLink;
