import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import UserLinkTree from './components/UserLinkTree';
import NotFound from './components/NotFound';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Help from './pages/Help/Help';
import FAQ from './pages/Help/FAQ';
import Ticket from './pages/Help/Ticket';

import HelpAnalytics from './pages/Help/Get Started/HelpAnalytics';
import GetStart from './pages/Help/Get Started/GetStart';
import CreateLink from './pages/Help/Get Started/CreateLink';
import CreateAccount from './pages/Help/Get Started/CreateAccount';
import LemmonlinkCommunity from './pages/Help/Get Started/LemmonlinkCommunity';
import UpdateAvatarTheme from './pages/Help/Get Started/UpdateAvatarTheme';
import URLEverywhere from './pages/Help/Get Started/URLEverywhere';
import VerifyAccount from './pages/Help/Get Started/VerifyAccount';
import State from './pages/State';
import Confirmation from './pages/confirmation';
import Passreset from './pages/passreset';
import Policy from './pages/policy';

export const Loader = createContext();
const App = () => {
  const [rerender, reload] = useState(false);
  const { t, i18n } = useTranslation();

  const isAuth = { token: localStorage.getItem('auth-token') };
  const languageSelected = localStorage.getItem('language');

  useEffect(() => {
    if (languageSelected) i18n.changeLanguage(languageSelected);
  }, [languageSelected]);

  return (
    <Loader.Provider value={{ loader: [rerender, reload] }}>
      <Router>
        <div>
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                isAuth.token ? <Redirect to="/admin" /> : <Home />
              }
            />
            <Route
              path="/admin"
              render={() => (isAuth.token ? <State /> : <Redirect to="/" />)}
            />
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={Login} />
            <Route
              path="/confirmation/:token/:email"
              component={Confirmation}
            />
            <Route path="/passreset/:token/:email" component={Passreset} />

            <Route path="/help/verifyaccount" component={VerifyAccount} />
            <Route
              path="/help/lemmonlinkcommunity"
              component={LemmonlinkCommunity}
            />
            <Route path="/help/analytics" component={HelpAnalytics} />
            <Route path="/help/updatesettings" component={UpdateAvatarTheme} />
            <Route path="/help/lemmonlinkurl" component={URLEverywhere} />
            <Route path="/help/createlink" component={CreateLink} />
            <Route path="/help/createaccount" component={CreateAccount} />
            <Route path="/help/start" component={GetStart} />
            <Route path="/help/ticket" component={Ticket} />
            <Route path="/help/faq" component={FAQ} />
            <Route path="/help" component={Help} />

            <Route path="/policy" component={Policy} />

            {/* All other paths need to remain above the username path */}
            <Route path="/:username" component={UserLinkTree} />

            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </Loader.Provider>
  );
};

export default App;
