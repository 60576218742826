import React from 'react';

const Link = ({link }) => {
  const formatURL = () => {
    const substring = '//';

    if (link.shorturl.includes(substring)) {
      return link.shorturl;
    } else {
      const newURL = `//${link.shorturl}`;
      return newURL;
    }
  };

  return (
    <div className="link">
      <a
        className="linkContainer"
        href={formatURL()}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: 'large' }}
      >
        {link.linkTitle}
      </a>
    </div>
  );
};

export default Link;
