import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
const HelpAnalytics = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('__als')} />
      <div className="ulgetstart" style={{ textAlign: 'justify' }}>
        <p>
        {t('als01')}
        </p>

        <strong>{t('als02')}</strong>
        <p>
        {t('als03')}
        </p>

        <Link to="/help/start">
          <Button>{t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default HelpAnalytics;
