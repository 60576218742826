import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Button, Result } from 'antd';

const FormTicket = () => {
  const [submit, setSubmit] = useState(false);
  const { t } = useTranslation();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = (values) => {
    setSubmit(true);
    axios.post('/users/help', values.user).catch((err) => console.log(err));
  };

  return !submit ? (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      className="formText"
    >
      <Form.Item
        name={['user', 'name']}
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['user', 'email']}
        label="Email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['user', 'subject']}
        label="Subject"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'Description'}
        label="Description"
        rules={[
          {
            required: true,
            max: 1000,
            min: 25,
          },
        ]}
      >
        <Input.TextArea style={{ height: '160px' }} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button className="buttontext" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <Result
      status="success"
      title="Your question was submitted succesfully!"
      subTitle="We will contact you as soon as possible."
      extra={[
        <Button onClick={() => setSubmit(false)} key="console">
          <Link to="/help/ticket">Submit another ticket</Link>
        </Button>,
        <Button className="buttontext" key="buy">
          <Link to="/" className="buttontext">
            Go Home
          </Link>
        </Button>,
      ]}
    />
  );
};

export default FormTicket;
