import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import formValidation from '../components/FormValidation';
import inputErrors from '../components/InputErrors';

const Login = (props) => {
  const initialState = {
    username: '',
    password: '',
  };

  const [userNotFound, setUserNotFound] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [deactive, setDeactive] = useState(false);
  const [res, setRes] = useState('');
  const { t } = useTranslation();
  const language = localStorage.getItem('language');

  const axiosFunc = () => {
    setUserNotFound(false);
    setWrongPassword(false);
    axios
      .post(`/users/login`, {...values, language})
      .then((res) => {
        // Save JWT token in localStorage
        localStorage.setItem('auth-token', res.data);

        // Redirect user to admin page
        window.location = '/admin';
      })
      .catch((err) => {
        const error = err.response.data;

        if (error === 'Username/Email not found') {
          setUserNotFound(true);
        }
        if (error === 'Invalid password') {
          setWrongPassword(true);
        }
        if (error === 'Email not verified.') {
          setEmailVerify(true);
        }
        if (error === 'Deactivated') {
          setDeactive(true);
        }
      });
  };
  const activate = () => {
    setUserNotFound(false);
    setWrongPassword(false);
    axios
      .post(`/users/activate`, values)
      .then((res) => {
        // Save JWT token in localStorage
        localStorage.setItem('auth-token', res.data);

        // Redirect user to admin page
        window.location = '/admin';
      })
      .catch((err) => {
        const error = err.response.data;

        if (error === 'Username/Email not found') {
          setUserNotFound(true);
        }
        if (error === 'Invalid password') {
          setWrongPassword(true);
        }
        if (error === 'Email not verified.') {
          setEmailVerify(true);
        }
      });
  };
  const resend = () => {
    setEmailVerify(false);
    axios
      .post(`/users/resend`, {...values, language})
      .then((res) => {
        setRes(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        if (error === 'email is required') {
          setRes(error);
        }
        if (error === 'We were unable to find a user with that email.') {
          setRes(error);
        }
        if (
          error === 'This account has already been verified. Please log in.'
        ) {
          setRes(error);
        }
      });
  };
  const forgotpassword = () => {
    setEmailVerify(false);
    axios
      .post(`/users/forgotpassword`, {...values, language})
      .then((res) => {
        console.log(res);
        setRes(res.data);
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data;
        if (error === 'email is required') {
          setRes(error);
        }
        if (error === 'We were unable to find a user with that email.') {
          setRes(error);
        }
      });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
  } = formValidation(initialState, inputErrors, axiosFunc);
  console.log(errors);
  return (
    <div className="login">
      <div className="container-fluid row global">
        <div className="background-sign-in row">
          <div className="col-2"></div>
          <div className="col-7 align-self-center" style={{ marginTop: '0px' }}>
            <img
              alt=""
              className="logo"
              src="../../../../assets/logo-light.svg"
            />

            <app-login-form>
              <div className="row">
                <div className="col">
                  <h5
                    style={{
                      marginLeft: ' -8%',
                      marginBottom: ' 3rem',
                      fontWeight: 'normal',
                    }}
                  >
                   {t("signIn")}
                  </h5>
                </div>
              </div>
              <div className="row">
                <button
                  onClick={() => props.history.push('/signup')}
                  className="get-started"
                >
                  <span className="no-account">{t('createAccount')}</span>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <input
                    className={
                      errors.username || userNotFound
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    placeholder={t("placeHolderLoginUser")}
                    required=""
                    type="text"
                  />
                  
                  {(errors.username || userNotFound) && (
                    <div className="container">
                      <div className="validation-error">
                        {errors.username}
                        {userNotFound ? 'Username/Email not found' : ''}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row" style={{ paddingTop: '5%' }}>
                  <input
                    className={
                      errors.password || wrongPassword
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    placeholder={t("placeHolderLoginPassword")}
                    type="password"
                  />
                  {(errors.password || wrongPassword) && (
                    <div className="container">
                      <div className="validation-error">
                        {errors.password}
                        {wrongPassword ? 'Invalid Password' : ''}
                      </div>
                    </div>
                  )}
                  {emailVerify && (
                    <div className="container">
                      <div className="validation-error">
                       {t("emailVerify")}
                      </div>
                    </div>
                  )}
                  {res && (
                    <div className="container">
                      <div className="validation-error">{res}</div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <button
                    className="btn"
                    style={{ fontWeight: 'bold' }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("login")}
                  </button>
                </div>
              </form>
            </app-login-form>
            {emailVerify && (
              <div className="col" style={{ paddingTop: '10px' }}>
                <button
                  onClick={() => resend()}
                  className="get-started"
                  style={{ minWidth: '135px' }}
                >
                  <span className="no-account">{t("resendEmail")}</span>
                </button>
              </div>
            )}
            {deactive && (
              <div className="col" style={{ paddingTop: '10px' }}>
                <h5>{t("accountDisabled")}</h5>
                <button
                  onClick={() => activate()}
                  className="btn"
                  style={{ minWidth: '135px' }}
                >
                  {t("activateAccount")}
                </button>
              </div>
            )}
            <div className="Remember-me row justify-content-center">
              <div className="col">
                <a className="Forgot-password" onClick={() => forgotpassword()}>
                {t("forgotPassword")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col Background-Right">
          <h1 style={{ marginTop: '38%', color: 'black' }}>
            <b>
             {t("loginBanner1")}
              <br />
              {t("loginBanner2")}
            </b>
          </h1>
          <div className="mt-5" style={{ fontSize: '1.4vw' }}>
            <p>
              <b>
              {t("loginBanner3")}
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
