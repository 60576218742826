import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Model from 'react-modal';
import AvatarComponent from './AvatarComponent';
import ClipLoader from 'react-spinners/ClipLoader';

const modalStyle = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '260px',
    height: '350px',
    background: 'white',
    padding: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(47, 53, 62, 0.86)',
  },
};

const Modal = ({ username, avatar, rerender, removeAvatar, set }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {avatar ? (
        <div className="avatar">
          <input
            type="image"
            src={avatar}
            alt="avatar-select"
            className="avatarStyle"
            onClick={openModal}
          ></input>
        </div>
      ) : (
        <div className="avatarStyle">
          <ClipLoader
            sizeUnit={'px'}
            size={96}
            color={'rgb(31, 28, 28)'}
            loading={true}
          />
        </div>
      )}
      <div className="setting-page-button">
        <button className="button yellow" onClick={openModal}>
        {t("pickImage")}
        </button>
        <button className="button grey" onClick={removeAvatar}>
        {t("removeImage")}
        </button>
      </div>
      <Model
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
        ariaHideApp={false}
      >
        <AvatarComponent
          closeModal={closeModal}
          username={username}
          avatar={avatar}
          rerender={rerender}
          set={set}
        />
      </Model>
    </>
  );
};

export default Modal;
