import React from 'react';
import { useTranslation } from 'react-i18next';
import { Statistic, Row, Col } from 'antd';

const LinkAnalysis = ({ linkStats, setAnalysis, openAnalysis }) => {
  const { t } = useTranslation();
  const centerStyle = { textAlign: 'center', justifyContent: 'center' };
  return (
    <div
      className="link__tab__panel"
      style={{ display: openAnalysis ? 'block' : 'none' }}
    >
      <div className="panel__header">
        <h5>{t('linkAnalysis')}</h5>

        <a className="x" data-close onClick={() => setAnalysis(false)}>
          ×
        </a>
      </div>
      <div className="panel__body">
        <Row gutter={24}>
          <Col span={6}>
            <Statistic
              title="Today"
              style={centerStyle}
              value={linkStats.day}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Last week"
              style={centerStyle}
              value={linkStats.week}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="This month"
              style={centerStyle}
              value={linkStats.month}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Total"
              style={centerStyle}
              value={linkStats.total}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LinkAnalysis;
