import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from '../../../components/Footer';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
const CreateLink = () => {
  const { t } = useTranslation();
  return (
    <div
      className="home body-wrapper mainPage"
      style={{ visibility: 'visible' }}
    >
      <Navbar title={t('lmltitle')} />
      <div className="ulgetstart">
        <p>
          <span>
            <strong>
              <span>{t('lml01')}</span>
            </strong>
            <span>
              <strong>{t('lml02')};</strong>
            </span>
          </span>
          <span>
            <span>
              <br />
            </span>
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span>
                {t('lml03')}
                <a href="http://lemmonlink.com/login">{t('lml04')}</a>;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                {t('lml05')}
                <span>{t('lml06')}</span>;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                {t('lml07')}
                <strong>{t('lml08')}</strong>’ - a link builder will appear
                below;
              </span>
            </p>
          </li>

          <li>
            <p>
              <span>{t('lml09')}</span>
            </p>
          </li>
          <li>
            <p>
              <span>{t('lml10')}</span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span>
            <span>
              <span>
                <strong>{t('lml11')}</strong>
              </span>
            </span>
          </span>
        </p>
        <p>
          <span>
            <br />
            {t('lml13')}
          </span>
        </p>
        <ul>
          <li>
            <span>{t('lml14')}</span>
          </li>
          <li>
            <span>{t('lml15')}'</span>
          </li>
          <li>
            <span>{t('lml16')}</span>
          </li>
          <li>
            <span>{t('lml17')}</span>
          </li>
        </ul>
        <div>
          <br />
          <strong>{t('lml18')}</strong>
        </div>
        <p>
          <span>
            {t('lml21')}
            <Link to="/help/ticket">{t('lml22')}</Link>
          </span>
        </p>

        <Link to="/help/start">
          <Button>{t('goback')}</Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default CreateLink;
