import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import banner from '../assests/images/banner.png';
import transparente from '../assests/images/transparente.png';
import logo from '../assests/images/logo.png';
import logolink from '../assests/images/logo-link.jpg';
import ReactPlayer from 'react-player';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import USVideo from '../assests/videos/LemmonLink-videoST-EN.mp4';
import ESVideo from '../assests/videos/LemmonLink-videoST.mp4';
import PTVideo from '../assests/videos/LemmonLink-videoST-BR.mp4';
import 'react-flags-select/css/react-flags-select.css';

const Home = () => {
  const { t, i18n } = useTranslation();
  const [play, setPlay] = useState(false);
  const languageSelected = localStorage.getItem('language');

  const changeLanguage = (lng) => {
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng);
  };

    useEffect(() => {
    if (languageSelected === null) changeLanguage("us");
    changeLanguage(languageSelected);
  }, [languageSelected]);

  return (
    <div className="home">
      <div className="header_area">
        <div className="navbar">
          <div className="navbar-header">
            <div className="container">
              <div className="basic-wrapper">
                <Link className="navbar-brand" to="/">
                  <img
                    src={logo}
                    alt="Lemmonet Influencers"
                    data-src={logo}
                    data-ret={logo}
                    className="retina"
                    style={{ display: 'inline' }}
                  />
                </Link>
                <a
              className="btn responsive-menu pull-right"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <i className="icon-menu" />
            </a>{" "}
              </div>
              <nav className="collapse navbar-collapse pull-right">
                <ul className="nav navbar-nav">
                  <li>
                    <ReactFlagsSelect
                      defaultCountry={
                        languageSelected ? languageSelected.toUpperCase() : 'US'
                      }
                      countries={['US', 'ES', 'PT']}
                      customLabels={{
                        US: 'English',
                        ES: 'Spanish',
                        PT: 'Portuguese',
                      }}
                      onSelect={(countryCode) =>
                        changeLanguage(countryCode.toLowerCase())
                      }
                    />
                  </li>
                  <li>
                    <Link to="/help" className="link-header">
                    {t('help')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="link-header">
                      {t('login')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup" className="link-header">
                      <span className="link-header2">{t('register')}</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="element-banner">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <h1
                  className="text-title animated fadeInLeft delay-01"
                  data-animation="fadeInLeft"
                  data-animation-delay="01"
                >
                  {t('header')} <br />
                  {t('header1')} <br />
                  {t('header2')}
                </h1>
                <a
                  href="https://lemmonlink.com/signup"
                  target="_blank"
                  className="link-header"
                >
                  <span className="link-header2">{t('text9')}</span>
                </a>
              </div>
              <div className="col-sm-6 col-xs-12 area-picture">
                <img
                  src={banner}
                  style={{ width: '100%' }}
                  className="hidden-sm hidden-lg hidden-md"
                  alt="WE KNOW HOW TO DRIVE RESULTS ON SOCIAL"
                  data-animation="fadeInRight"
                  data-animation-delay="03"
                />
                <img
                  src={transparente}
                  style={{ width: '100%' }}
                  className="hidden-xs imgbanner"
                  alt="WE KNOW HOW TO DRIVE RESULTS ON SOCIAL"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="area-full">
        <div className="divide50" id="cases" />
        <div className="light-wrapper">
          <div className="container inner text-center" id="cases">
            <h2 className="h2light">{t('text3')}</h2>
            <div className="divide40" />
            <h2 className="h2light">
              {t('text')}
              <br /> {t('text1')}
            </h2>
            <div className="divide40" />
            <div className="video " onClick={() => setPlay(!play)}>
              <div
                className="playpause"
                style={{
                  backgroundImage: play
                    ? ''
                    : 'url(https://img.icons8.com/material-two-tone/24/000000/play-button-circled.png)',
                }}
              />
              <ReactPlayer
                url={
                  languageSelected === null
                    ? USVideo
                    : languageSelected.toUpperCase() == 'US'
                    ? USVideo
                    : languageSelected.toUpperCase() == 'ES'
                    ? ESVideo
                    : PTVideo
                }
                playing={play}
              />
            </div>

            <div className="divide40" />
            <h1>{t('text2')}</h1>
            <div className="divide40" />
            <a
              href="https://lemmonlink.com/signup"
              target="_blank"
              className="link-header"
            >
              <span className="link-header2">{t('text9')}</span>
            </a>

            <div className="divide30" />
            <h2 className="h2light">{t('text4')}</h2>
            <div className="divide30" />
            <h2 className="h2light">
              {t('text5')} <br />
              {t('text6')}
            </h2>
          </div>
        </div>

        <div className="light-wrapper" id="contact">
          <div className="container inner">
            <div className="text-center">
              <img
                src={logolink}
                className="home logo"
                style={{ width: '25%' }}
              />
              <div className="divide100" />
              <a
                href="https://lemmonlink.com/signup"
                target="_blank"
                className="link-header"
              ></a>
              <br />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
