import React from 'react';
import { Statistic, Row, Col } from 'antd';


const Stats = ({ globalReports }) => {
  return (
    <div>
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <Statistic title="Total Users" value={globalReports.usersCount} />
        </Col>
        <Col span={6}>
          <Statistic title="Active Links" value={globalReports.activeLinks} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Links created today"
            value={globalReports.newLinks}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Users without links"
            value={globalReports.usersWithoutLinks}
          />
        </Col>
      </Row>
      
    </div>
  );
};

export default Stats;
