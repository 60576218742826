import { useState, useEffect } from 'react';


const FormValidation = (initialState, validate, reqFunc) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        reqFunc();
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
  }, [isSubmitting]);

  function handleChange(event) {
    setErrors({});
    if (event.target.type === 'checkbox') {
      setValues({
        ...values,
        [event.target.name]: !values.visible,
      });
      handleSubmit()
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  }

  function handleSubmit(event) {
    if (event) event.preventDefault();
    values.visible = true
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  }

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    setErrors,
    isSubmitting,
    setValues,
  };
};

export default FormValidation;
